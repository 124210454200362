import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
export const SharedData = createContext([])

function CallApi({ children }) {
 
    const [bookingValidStep1, setBookingValidStep1] = useState(false);

    const [fetchCities, setFetchCities] = useState([]);
    const fetchCitiesData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/cities?type=2`;
        let { data } = await axios.get(url)
        setFetchCities(data);
    }


    const [fetchAllCities, setFetchAllCities] = useState([]);
    const fetchAllCitiesData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/cities?type=0`;
        let { data } = await axios.get(url)
        setFetchAllCities(data);
    }
    const [fetchPersonalData, setFetchPersonalData] = useState([]);
    const [clientStatus, setClientStatus] = useState(null);
    const [clientId, setClientId] = useState(null);
    const fetchPersonal = async () => {
        const getcClientId = localStorage.getItem('client_id');
        if (getcClientId !== null) {
            const url = `${process.env.REACT_APP_API_URL}/profile?clientId=${getcClientId}`;
            let { data } = await axios.get(url)
            setFetchPersonalData(data);
            setClientStatus(data?.client_status)
            if (data?.first_time === false) {
                setBookingValidStep1(data?.first_time); 
            }  else if (data?.first_time === true) {
                setBookingValidStep1(data?.first_time);  
            }

        } else if (clientId !== null) {
            const url = `${process.env.REACT_APP_API_URL}/profile?clientId=${clientId}`;
            let { data } = await axios.get(url)
            setFetchPersonalData(data);
        }
    }

    const [aoutUsData, setAboutusData] = useState([]);
    const fetchAboutus = async () => {
        const url = `${process.env.REACT_APP_API_URL}/about`;
        let { data } = await axios.get(url)
        setAboutusData(data);
    }

    const [contactsData, setContactsData] = useState([]);
    const fetchContacts = async () => {
        const url = `${process.env.REACT_APP_API_URL}/contacts`;
        let { data } = await axios.get(url)
        setContactsData(data);
    }

    const [bannersHome, setBannersHome] = useState([]);
    const [carsSliderHome, setCarsSliderHome] = useState([]);
    const [offersHome, setOffersHome] = useState([]);
    const [branchesHome, setBranchesHome] = useState([]);
    const [carBrandsHome, setCarBrandsHome] = useState([]);
    const [LoadingHome, setLoadingHome] = useState(false);
    const fetchHomePage = async () => {
        setLoadingHome(true)
        try {

            const url = `${process.env.REACT_APP_API_URL}/home`;
            let { data } = await axios.get(url)
            setBannersHome(data?.banners);
            setCarsSliderHome(data?.cars);
            setOffersHome(data?.offers);
            setBranchesHome(data?.branches);
            setCarBrandsHome(data?.brands);
            setTimeout(() => {
                setLoadingHome(false)
            }, 2000);
        } catch (error) {
            setLoadingHome(false)

        }

    }


    const [configData, setConfigData] = useState({});
    const [value, setValue] = useState([]);
    const [maxValue, setMaxValue] = useState(0);
    const [minValue, setMinValue] = useState(0);

    const fetchConfig = async () => {
        const url = `${process.env.REACT_APP_API_URL}/configs`;
        let { data } = await axios.get(url)
        setConfigData(data);
        setValue([data.start_price, data.end_price])
        setMaxValue(data.end_price)
        setMinValue(data.start_price)
    }
    useEffect(() => {
        fetchCitiesData()
        fetchAllCitiesData()
        fetchAboutus()
        fetchContacts()
        fetchHomePage()
        fetchConfig()
    }, [])

    useEffect(() => {
        fetchPersonal()
    }, [clientId])
    return (
        <SharedData.Provider
            value={{
                maxValue,
                LoadingHome,
                fetchCities,
                fetchPersonalData,
                aoutUsData,
                bannersHome,
                carsSliderHome,
                offersHome,
                branchesHome,
                carBrandsHome,
                contactsData,
                setClientId,
                fetchPersonal,
                configData,
                value,
                fetchConfig,
                setValue,
                minValue,
                fetchAllCities,
                bookingValidStep1,
                setBookingValidStep1,
                clientStatus
            }}
        >
            {children}
        </SharedData.Provider>
    )
}

export default CallApi