import { Player } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import { format, isValid, parse } from 'date-fns';
import { arSA, enUS } from 'date-fns/locale';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Component from '../../../constants/Component';
import { LocalizationContext } from '../../../context/LangChange';
import './style.scss';

const Blogs = () => {
    let { isLang } = useContext(LocalizationContext);
    const [blogPosts, setBlogPosts] = useState([]);

    const fetchData = async () => {
        let data = await axios.get(`https://alghazal.sa/gazalservices/blogs?lang=${isLang}`)
        setBlogPosts(data?.data);
    }
    useEffect(() => {
        fetchData()
        window.scrollBy(0, -window.pageYOffset);
    }, [isLang]);

    const parseDateString = (dateString) => {
        return parse(dateString, 'dd/MM/yyyy', new Date());
    };
    return (
        <div>
            <div className="app_blogs">
                <Container>
                    <div className='pt-5'>
                        <Component.Title title={isLang == "en" ? 'Blogs' : 'المدونات'} />
                    </div>
                    <Row className='pt-50 flex justify-content-center '>

                        {
                            blogPosts.length <= 0 ?
                                <Col xl={4} lg={4} md={6} sm={12} style={{ marginBottom: '50px' }}>
                                    <motion.div
                                        whileInView={{ scale: [0, 1] }}
                                        transition={{ duration: 1, type: 'tween' }}
                                    >
                                        <Player
                                            autoplay
                                            loop
                                            src={'https://lottie.host/85c76b0b-92c2-4af2-b75f-d987f33bd0aa/8KLhvFocvr.json'}
                                        />
                                    </motion.div>
                                </Col> :
                                <>
                                    <Row className='mb-8' dir={isLang == 'ar' ? 'rtl' : 'ltr'}>
                                        {blogPosts.map((item, index) => (
                                            <Col key={index} xl={4} lg={4} md={12} sm={24} xs={24} className='mt-4'>
                                                <Link to={`/blogs/${item.id}/${item.slug}`}>
                                                    <Card className="blog_card h-100 shadow-sm" dir={isLang == 'ar' ? 'rtl' : 'ltr'}>
                                                        <Link to={`/blogs/${item.id}/${item.slug}`} className={`  header_blog`}>
                                                            <Card.Img
                                                                variant="top"
                                                                src={item.image}
                                                                alt={item.title}
                                                                className={`blog_image  `}
                                                            />
                                                        </Link>
                                                        <Card.Body>

                                                            <div className="blog_meta  d-flex gap-3 align-items-center">
                                                                <i className='pi pi-calendar'></i>
                                                                {isValid(parseDateString(item.date)) ? (
                                                                    <span className='blog_date'>
                                                                        {isLang === 'ar'
                                                                            ? format(parseDateString(item.date), 'dd MMMM yyyy', { locale: arSA })
                                                                            : format(parseDateString(item.date), 'MMMM dd, yyyy', { locale: enUS })
                                                                        }
                                                                    </span>
                                                                ) : (
                                                                    <span>Invalid date</span>
                                                                )}
                                                            </div>
                                                            <div className="blogs_data">
                                                                <h3 className='mt-2'>{item.title}</h3>
                                                                <p>{item.short.split(" ").slice(0, 15).join(" ")}</p>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            </Col>
                                        ))}
                                    </Row>
                                </>
                        }


                    </Row>

                </Container>
            </div>
        </div>
    )
}

export default Blogs