import axios from 'axios'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { useNavigate, useParams } from 'react-router-dom'
import Component from '../../../constants/Component'
import Icons from '../../../constants/Icons'
import img from '../../../constants/Img'
import { SharedData } from '../../../context/CallApi'
import { LocalizationContext } from '../../../context/LangChange'
import Slider from '../../website/Payment/Slider'

const CarPayDetails = () => {
    const toast = useRef(null);
    const msg = useRef(null);
    const [showReason, setShowReason] = useState(null);
    const handleClose = () => setShowReason(false);
    const [errorMessage, setErrorMessage] = useState('');


    let navigate = useNavigate();

    let { isLang, reservationsStartDate, reservationsEndDate, reservationsTotal } = useContext(LocalizationContext);
    let { id } = useParams()
    const [detailsData, setDetailsData] = useState([]);
    let { contactsData } = useContext(SharedData);
    const urlSearchParams = new URLSearchParams(window.location.search);

    const fetchCarsDetails = async () => {
        const url = `${process.env.REACT_APP_API_URL}/resdetail?resId=${id}`;
        let { data } = await axios.get(url)
        setDetailsData(data);
    }
    useEffect(() => {
        fetchCarsDetails()
        window.scrollBy(0, -window.pageYOffset);
    }, [id])
    const fetchCancelresservlet = async () => {
        const message = msg.current.value;

        if (!message || message.trim() === '') {
            setErrorMessage(isLang === "en" ? 'Please enter the reason for canceling your booking.' : 'برجاء ادخال سبب إلغاء الحجز.');
            return;
        }

        const url = `${process.env.REACT_APP_API_URL}/cancelresservlet?resId=${id}&msg=${msg?.current.value}`;
        let { data } = await axios.post(url)
        show()
        setTimeout(() => {
            navigate(`/${isLang}/account/manage`)
            handleClose()
        }, 2000);

    }
    const handleInputChange = () => {
        setErrorMessage('');  // Clear the error message on any input change
    }
    const show = () => {
        toast.current.show({ severity: 'warn', summary: isLang == "en" ? 'Canceled' : 'ألغيت', detail: isLang == "en" ? 'Your reservation has been cancelled' : 'تم إلغاء الحجز ', life: 3000 });
    };
    return (
        <div>
            <Toast ref={toast} position={'bottom-center'} />

            <Slider banners={detailsData.banners} />
            <div className={`payment_bg relative ${detailsData?.banners?.length > 0 ? '' : 'banners_top_100'} `}>
                <Container>
                    <div className="app__payment_details mt-40 mb-40" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        <Component.Title title={`${isLang === "en" ? detailsData.brand : detailsData.brand_arabic}` + '  ' + `${isLang === "en" ? detailsData.sub_brand : detailsData.sub_brand_arabic}`} />
                        <Row >
                            <Col xl={12} lg={12} md={12} sm={12} className='pt-40  ' >
                                <div className="slider_card flex flex-row gap-8 " dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                    <ul>
                                        <li>
                                            <Icons.SliderCarSedan />
                                            <strong className='bold'>{detailsData.year} {' '} {isLang === "en" ? detailsData.type : detailsData.type_arabic}  </strong>
                                        </li>
                                        {
                                            localStorage.getItem('res') === 'current-booking' &&
                                            <>
                                                {
                                                    detailsData?.res_type === 0 ?
                                                        <li>
                                                            <Icons.SliderCarMoney />
                                                            {detailsData.offer !== 0 && <span className='app__discount'>{detailsData.price} {isLang === "en" ? 'SR' : 'ريال'} </span>}
                                                            {
                                                                detailsData.offer === 0 ?
                                                                    <span>{detailsData.price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                                    :
                                                                    <span>{detailsData.offer_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                            }
                                                            <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>
                                                        </li> :
                                                        <li>
                                                            <Icons.SliderCarMoney />
                                                            <span>{detailsData.lim_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                            <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>
                                                        </li>
                                                }
                                                <li>
                                                    <Icons.SliderCarMoney />
                                                    <span>{isLang === "en" ? 'Total price' : 'إجمالي المبلغ : '}</span>
                                                    <span>{reservationsTotal} {isLang === "en" ? 'SAR' : 'ريال'}   </span>
                                                </li>

                                            </>
                                        }
                                        <li>
                                            <Icons.SliderCarPeople />
                                            <span>{detailsData.pasengers} {isLang === "en" ? 'Pasengers' : 'راكب'}   </span>
                                        </li>
                                        {detailsData.aircondition !== 0 && <li>
                                            <Icons.SliderCarSoil />
                                            <span>{isLang === "en" ? 'Aircondition' : 'مكيفة'} </span>
                                        </li>}


                                    </ul>

                                    <ul>
                                        <li className='Calendar_car_details'>
                                            <Icons.Calendar />
                                            {/* <div className="Calendar_car_details_content">  */}
                                            <span>   {isLang === "en" ? "Booking start date" : 'تاريخ بدء الحجز'}     </span><span className='px-5 '> {detailsData?.start_date}</span>
                                            {/* </div> */}
                                        </li>
                                        <li className='Calendar_car_details'>
                                            <Icons.Calendar />
                                            <span>  {isLang === "en" ? "Booking end date" : 'تاريخ نهاية الحجز'}      </span><span className='px-5 '>{detailsData?.end_date}</span>
                                        </li>
                                    </ul>

                                </div>

                                <div className="mt-40">
                                    <Component.Title title={isLang === "en" ? 'For More, Contact Us' : 'للمزيد تواصل معنا'} />

                                    <div className="contact_us_car_details">
                                        <a href={`mailto:${contactsData?.email}`} target='_blank' className="email">
                                            <Icons.Email />
                                        </a>

                                        <a href={`tel:${contactsData?.phone}`} target='_blank' className="call">
                                            <Icons.Phone />
                                        </a>
                                        <a href={`https://wa.me/${contactsData?.whatsapp}`} target='_blank' className="call">
                                            <Icons.Chat />
                                        </a>
                                        {/* 
                                        <a href={`https://alghazal.sa/uploader/uploads/1700086758097.pdf`} download target='_blank' className="call">
                                            <Icons.Bill />
                                        </a>


                                        <a href={`https://alghazal.sa/uploader/uploads/1700086712888.pdf`} download target='_blank' className="call"  >
                                            <Icons.vill />
                                        </a> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {
                           localStorage.getItem('pay') == 0 &&
                            <div className="flex justify-content-center">
                                <button type="button"
                                    onClick={(e) => {
                                        setShowReason(true)
                                    }}
                                    width="300px" className="success_btn px-5">
                                    {isLang == "en" ? 'Cancellation Of Reservation' : 'إلغاء الحجز'}
                                </button>
                            </div>
                        }
                    </div>
                </Container>
                <div className='absolute Bg_payment    '>
                    <Icons.Bg_payment />
                </div>
            </div>

            <Modal
                show={showReason}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                animation={true}
            >
                <Modal.Body>
                    <div className='terms_popup mt pb-4 px-2'>
                        <Container dir={isLang == "en" ? 'ltl' : 'rtl'} className='  flex-column'>
                            <header className='flex justify-content-center'>
                                <img src={img.Logo3} alt="" width={150} />
                            </header>
                            <label htmlFor="old" className="font-bold block mb-2">   {isLang === "en" ? 'Reason for cancellation' : 'سبب إلغاء الحجز'} </label>

                            <InputTextarea
                                type="search"
                                className="w-full p-inputtext-sm"
                                ref={msg}
                                onChange={handleInputChange}

                            />
                            {errorMessage && <small style={{ color: 'red' }}>{errorMessage}</small>}

                            <div className=" mt-3    flex justify-content-center align-items-center gap-4 w-100">
                                <Button
                                    severity="warning"
                                    dir='ltr'
                                    size='small'
                                    label={isLang === "en" ? 'Confirm' : 'تــاكيد'}
                                    onClick={fetchCancelresservlet}
                                />
                                <div className="outlined">
                                    <Button
                                        severity="warning"
                                        dir='ltr'
                                        size='small'
                                        outlined
                                        label={isLang === "en" ? 'Cancel' : 'إلغــاء'}
                                        onClick={handleClose}
                                    />

                                </div>
                            </div>
                        </Container>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default CarPayDetails
