import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Component from '../../../constants/Component';
import { SharedData } from '../../../context/CallApi';
import Slider from './Slider/Slider';
import { Helmet } from 'react-helmet-async';


const Home = () => {


  let { bannersHome, carsSliderHome, offersHome, branchesHome, carBrandsHome } = useContext(SharedData)
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('path', currentPath)
    window.scrollBy(0, -window.pageYOffset);
  }, [])
  return (

    <div>
      <Helmet >
        <meta name="title" content="شركة تأجير السيارات في المملكة العربية السعودية" />
        <meta name="description" content="تقدم شركة الغزال في المملكة العربية السعودية خدمات تأجير سيارات مخصصة للأفراد والشركات بأسعار تنافسية للغاية." />
        <meta name="keywords" content="الغزال لتأجير السيارات ، شركة تأجير السيارات في المملكة العربية السعودية ، شركات تأجير السيارات ، شركة تأجير السيارات في المملكة العربية السعودية ، تأجير السيارات في المملكة العربية السعودية ,  تطبيق لتاجير السيارات في المملكة العربية السعودية ، خدمات تأجير السيارات في المملكة العربية السعودية" />
      </Helmet>
      <Slider dataHome={bannersHome} />
      <Component.SearchCar branchesHome={branchesHome} carBrandsHome={carBrandsHome} />
      <Component.LatestCars carsSliderHome={carsSliderHome} />
      <Component.DownloadAppNow />
      {
        offersHome?.length >= 1 &&
        <Component.HomeOffers dataHome={offersHome} />
      }
      <Component.HomeContactUS />
      <Component.BlogSection />
      <Component.Services />
      {/* <Component.Loader /> */}

    </div>
  )
}

export default Home
