import axios from 'axios';
import { PanelMenu } from 'primereact/panelmenu';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Component from '../../../constants/Component';
import Icons from '../../../constants/Icons';
import { SharedData } from '../../../context/CallApi';
import { LocalizationContext } from '../../../context/LangChange';
import './style.scss';
import { Helmet } from 'react-helmet-async';

const Branches = () => {
  const [selectedCityIndex, setSelectedCityIndex] = useState('');
  let { isLang } = useContext(LocalizationContext);

  const handleCityChange = (index) => {
    setSelectedCityIndex(index);
  };

  let { fetchCities } = useContext(SharedData);
  const [branchesData, setBranchesData] = useState([]);

  const [openedItem, setOpenedItem] = useState(null);
  const [openedItems, setOpenedItems] = useState(null);


  const items = fetchCities.map((city) => ({
    label: isLang === "en" ? city.name : city.arabic_name,
    items: branchesData.map((branche) => {
      const branchItems = [];

      // Add the address if it's available
      if (branche.address) {
        branchItems.push({
          label: `${isLang === "en" ? 'address  :' : '   العنوان:'} ${isLang === "en" ? branche.address : branche.arabic_address}`,
          icon: <Icons.Fax style={{ width: '20px' }} />,
        });
      }

      /*      // Add the location if latitude and longitude are available
           if (branche.latitude && branche.longitude) {
             branchItems.push({
               label: isLang === "en" ? 'Select Location  ' : 'الموقع على الخريطة',
               icon: <Icons.Location style={{ width: '20px' }} />,
               command: () => handleCityChange(`https://www.google.com/maps?q=${branche.latitude},${branche.longitude}&hl=es;z%3D14&amp&output=embed `),
             });
           } */

      // Add the hotline if it's available
      if (branche.phone) {
        branchItems.push({
          label: isLang === "en" ? `   Hotline : ${branche.phone}` : ` الخط الساخن  : ${branche.phone}`,
          icon: <Icons.Phone style={{ width: '20px' }} />,
          command: () => {
            window.open(`https://wa.me/${branche.phone}`, '_blank');
          },
        });
      }

      // Add the mobile if it's available
      if (branche.mobile) {
        branchItems.push({
          label: isLang === "en" ? `Whatsapp : ${branche.mobile}` : `واتساب : ${branche.mobile.replace('+', '')}`,

          icon: <Icons.Chat style={{ width: '20px' }} />,
          command: () => {
            window.open(`https://wa.me/${branche.mobile}`, '_blank');
          },
        });
      }

      // Add the email if it's available
      if (branche.email) {
        branchItems.push({
          label: isLang === "en" ? `    Email   : ${branche.email}` : ` البريد الإلكترونى   : ${branche.email}`,
          icon: <Icons.Email style={{ width: '20px' }} />,
          command: () => {
            window.open(`mailto:${branche.email}`, '_blank');
          },
        });
      }

      // Add the working hours if it's available
      if (branche.working_hours) {
        branchItems.push({
          label: ` ${isLang === "en" ? 'work hours:' : ' ساعات العمل  :'} ${isLang === "en" ? branche.working_hours : branche.arabic_working_hours}`,
          icon: <Icons.Fax style={{ width: '20px' }} />,
        });
      }

      return {
        label: isLang === "en" ? branche.name : branche.arabic_name,
        items: branchItems,
        expanded: openedItems === branche.arabic_name,

        command: () => {
          if (!openedItems === branche.arabic_name) {
            setOpenedItems(null);
            handleCityChange(`https://www.google.com/maps?q=${branche.latitude},${branche.longitude}&hl=es;z%3D14&amp&output=embed `);
          } else {
            setOpenedItems(branche.arabic_name);
            handleCityChange(`https://www.google.com/maps?q=${branche.latitude},${branche.longitude}&hl=es;z%3D14&amp&output=embed `);
          }
        },
      };
    }),
    expanded: openedItem === city.arabic_name,
    command: () => {
      if (!openedItem === city.arabic_name) {
        setOpenedItem(null);
      } else {
        fetchAboutus(city.id);
        setOpenedItem(city.arabic_name);
      }
    },
  }));


  const fetchAboutus = async (id) => {
    setBranchesData([])
    const url = `${process.env.REACT_APP_API_URL}/branches?cityId=${id}`;
    let { data } = await axios.get(url)
    setBranchesData(data);
    if (id === 1) {
      setSelectedCityIndex(`https://www.google.com/maps?q=${data[0]?.latitude},${data[0]?.longitude}&hl=es;z%3D14&amp&output=embed `)
    }
    if (id === 2) {
      setSelectedCityIndex(`https://www.google.com/maps?q=${data[0]?.latitude},${data[0]?.longitude}&hl=es;z%3D14&amp&output=embed `)
    }
    if (id === 3) {
      setSelectedCityIndex(`https://www.google.com/maps?q=${data[0]?.latitude},${data[0]?.longitude}&hl=es;z%3D14&amp&output=embed `)
    }
  }
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
    fetchAboutus(1)

  }, [])

  return (
    <>
      <Helmet >
        <meta name="title" content="شركة تأجير السيارات في المملكة العربية السعودية" />
        <meta name="description" content="تقدم شركة الغزال في المملكة العربية السعودية خدمات تأجير سيارات مخصصة للأفراد والشركات بأسعار تنافسية للغاية." />
        <meta name="keywords" content="الغزال لتأجير السيارات ، شركة تأجير السيارات في المملكة العربية السعودية ، شركات تأجير السيارات ، شركة تأجير السيارات في المملكة العربية السعودية ، تأجير السيارات في المملكة العربية السعودية ,  تطبيق لتاجير السيارات في المملكة العربية السعودية ، خدمات تأجير السيارات في المملكة العربية السعودية" />
      </Helmet>
      <div className='app__Branches overflow-hidden' style={{ marginTop: '80px' }}>
        <Row dir={isLang === "en" ? 'rtl' : 'ltr'}>
          {/* Map Section */}
          <Col xl={8} lg={8} md={6} sm={12} className='map_h'>
            {selectedCityIndex !== null && (
              <iframe
                src={selectedCityIndex}
                title="وصف فريد للإطار"
                width="100%"
                style={{ border: '0px' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={12} dir={isLang === "en" ? 'ltr' : 'rtl'}>
            <Container fluid className='btn_Branches'>
              <div className={` ${isLang === "en" ? 'PanelMenu_dir' : 'PanelMenu_dirAr'}  mb-30  w-100 title_branches `}>
                <Component.Title title={isLang !== "en" ? 'فروعنا' : 'Branches'} />
                <PanelMenu model={items} className="w-full  mt-5" />
              </div>
            </Container>
          </Col>
        </Row>
      </div >
    </>
  );
};

export default Branches;
