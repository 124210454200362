import axios from 'axios';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Component from '../../../constants/Component';
import img from '../../../constants/Img';
import { LocalizationContext } from '../../../context/LangChange';
import './Career.scss';
import { Player } from '@lottiefiles/react-lottie-player';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

const Career = () => {
  const bgStyle = {
    backgroundImage: `url(${img.BgJobs})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%'
  };
  let { isLang } = useContext(LocalizationContext);
  const [jobsData, setJobsData] = useState([]);
  const fetchjobs = async () => {
    const url = `${process.env.REACT_APP_API_URL}/jobs`;
    let { data } = await axios.get(url)
    setJobsData(data);
  }
  useEffect(() => {
    fetchjobs()
    window.scrollBy(0, -window.pageYOffset);

  }, [])
  const [show, setShow] = useState(false);
  const [JobId, setJobId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true)
    setJobId(id)
  };
  return (
    <div style={bgStyle}>
      <Helmet>
        <meta name="title" content="شركة تأجير السيارات في المملكة العربية السعودية" />
        <meta name="description" content="تقدم شركة الغزال في المملكة العربية السعودية خدمات تأجير سيارات مخصصة للأفراد والشركات بأسعار تنافسية للغاية." />
        <meta name="keywords" content="الغزال لتأجير السيارات ، شركة تأجير السيارات في المملكة العربية السعودية ، شركات تأجير السيارات ، شركة تأجير السيارات في المملكة العربية السعودية ، تأجير السيارات في المملكة العربية السعودية ,  تطبيق لتاجير السيارات في المملكة العربية السعودية ، خدمات تأجير السيارات في المملكة العربية السعودية" />
      </Helmet>
      <div dir={isLang === "en" ? 'ltr' : 'rtl'} className=' mb-50 pt-50' style={{ marginTop: '80px' }}>

        <Container  >
          <Component.Title title={'الوظائف المتاحة'} />
          <Row className='pt-50 flex justify-content-center '>

            {
              jobsData.length <= 0 ?
                <Col xl={4} lg={4} md={6} sm={12} style={{ marginTop: '20px' }}>
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, type: 'tween' }}
                  >
                    <Player
                      autoplay
                      loop
                      src={'https://assets10.lottiefiles.com/packages/lf20_ceiqy4t3.json'}
                    />
                  </motion.div>
                </Col> :
                <>

                  {
                    jobsData?.map((item, index) => (
                      <Col key={index} xl={4} lg={4} md={6} sm={12} style={{ marginTop: '20px' }}>
                        <Card className='card_jobs'>
                          <div className="header">
                            <div className="header_img">
                              <img src={img.Logo2} alt="images" loading='lazy' />
                            </div>
                            <div className="content">
                              <h2>{isLang === "en" ? item.title : item.arabic_title}</h2>
                              <span>{isLang === "en" ? item.type : item.arabic_type}</span>
                            </div>
                          </div>
                          <div className="content_dec">
                            <p>{isLang === "en" ? item.desc : item.arabic_desc}</p>
                          </div>
                          <div className="btn__group" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                            <div className="btn2">
                              <Button label={isLang === "en" ? item.details : item.arabic_details} severity='' />
                            </div>
                            <div className="btn">
                              <Button rounded onClick={() => {
                                handleShow(item.id)
                              }} label={isLang === "en" ? 'Apply now' : 'قدم الان'} severity='warning' />
                            </div>
                          </div>
                        </Card>
                      </Col>
                    ))
                  }
                </>
            }


          </Row>
          <Component.Apply
            handleClose={handleClose}
            show={show}
            idJob={JobId}
            isLang={isLang}
          />
        </Container>
      </div>
    </div>
  )
}

export default Career